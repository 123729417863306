<template>
  <div v-if="booking && booking.details" class="BookingsWheelStorageOverview">
    <h3>Dæk til udlevering:</h3>
    <BookingCard :data="booking" class="BookingsWheelStorageOverview__wheel" />
  </div>
</template>

<script>
import { getWheelStorageBookings } from '@services/thansen/bookings';
import BookingCard from '@scenes/BookingScene/components/BookingCard';

export default {
  name: 'BookingsWheelStorageOverview',
  components: {
    BookingCard,
  },
  data() {
    return {
      booking: null,
    };
  },
  created() {
    getWheelStorageBookings()
      .then(response => {
        this.booking = response ? response[0] : null;
      })
      .catch(() => {});
  },
};
</script>

<style lang="scss" scoped>
.BookingsWheelStorageOverview {
  .BookingsWheelStorageOverview__wheel {
    max-width: 400px;
  }
}
</style>
